import React from 'react'
import { graphql } from "gatsby"
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Logo from '../../components/Logo'
import Navigation from '../../components/Navigation'
import '../../style/all.css'

class Impressum extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const post = get(this, 'props.data.allContentfulHomepage.nodes')[0]

    return (
      <Layout>
        <Logo />
        <Navigation menuText="Impressum" />

        <div>
          <Helmet title={`Impressum | Adrienn Bazsó`}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
          </Helmet>
          <section className="section-hallo">
            <div className="content">
              <h1>Impressum</h1>
              <div className="section-content">
                <div className="list-entry">
                  <div
                    className="blog-content"
                    dangerouslySetInnerHTML={{ __html: post.impressum.childMarkdownRemark.html }}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Impressum

export const pageQuery = graphql`
  query ImpressumQuery {
    allContentfulHomepage {
      nodes {
        impressum {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
