import React from 'react';
import Link from 'gatsby-link';
import logo from '../img/logo.png';
import '../style/hover.css'


export default () => (
    <nav className="navbar mobile-menu"  id="menuToggle">
        <input type="checkbox" />
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>

        <div id="navigation">

        <div className="navbarContainer">
            <ul className="navbar-start">
                <li className="navbar-item neues neues-effekt" >
                    <Link
                        to="/termine"
                        activeStyle={{

                        }}
                    >
                        Termine
                    </Link>
                </li>

                <li className="navbar-item projekte projekte-effekt">
                    <Link to="/projekte">
                        Projekte
                    </Link>
                </li>
                <li className="navbar-item about about-effekt">
                    <Link to="/vita">
                       Vita
                    </Link>
                </li>
                <li className="navbar-item kontakt kontakt-effekt">
                    <Link to="/kontakt">
                        Kontakt
                    </Link>
                </li>
            </ul>
        </div>
        </div>
    </nav>
);


